import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';
import LegalRepresentative from '@/Models/LegalRepresentative';

export default class Prospectus {
    constructor({
        prospectusId = 0, 
        branchOfficeId = null, 
        statusId = null, 
        personTypeId = null, 
        referenceNumber = '',
        branchOffice = null, 
        status = null, 
        personType = null, 
        generalInformation = new GeneralInformation(), 
        homeAddress = new FiscalAddress(),
        fiscalAddress = new FiscalAddress(),
        legalRepresentative = new LegalRepresentative(),
        representativeAddress = new FiscalAddress(),
        nationalAddress = new FiscalAddress(),
        createdAt = new Date(),
        updatedAt = new Date(),
    } = {}) {
        this.prospectusId = prospectusId;
        this.branchOfficeId = branchOfficeId;
        this.statusId = statusId;
        this.personTypeId = personTypeId;
        this.referenceNumber = referenceNumber;
        this.branchOffice = branchOffice;
        this.status = status;
        this.personType = personType;
        this.generalInformation = generalInformation;
        this.homeAddress = homeAddress;
        this.fiscalAddress = fiscalAddress;
        this.legalRepresentative = legalRepresentative;
        this.representativeAddress = representativeAddress;
        this.nationalAddress = nationalAddress;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}