<template>
    <div class="form-group" :class="colClass">
        <label v-if="label" :for="name">{{  label }}</label>
        <Field 
            v-model="selectedValue" 
            :as="as" :type="type" 
            class="form-control" 
            :name="name" 
            :placeholder="placeholder" 
            :rules="rules" 
            @update:modelValue="emitSelectedValue" 
            :disabled="disabled" />
        <ErrorMessage :name="name" class="input-error"></ErrorMessage>
    </div>
</template>

<script>
import { ref, watch } from "vue";
import { Field } from "vee-validate";

export default {
    props: {
        as: {
            type: String,
            default: 'input'
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        modelValue: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        rules: {
            type: [String, Function],
            default: '',
        },
        colClass: {
            type: String,
            default: 'col-sm-12'
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits:["update:modelValue"],
    data() {
        return {
            selectedValue: ref(this.modelValue || ""),
        }
    },
    methods: {
        emitSelectedValue() {
            this.$emit('update:modelValue', this.selectedValue);
        }
    },
    watch: {
        modelValue(newValue) {
            this.selectedValue = newValue;
            this.$emit('update:modelValue', this.selectedValue);
        }
    },
}
</script>