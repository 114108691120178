import { defineStore } from 'pinia';
import { computed } from 'vue';
import { getLocaleMessage } from '@/services/MessagesService';
import { useToast } from 'vue-toast-notification';
import { sharedStore } from '@/pinia/sharedStore.module';
import { useLoadingStore } from '@/pinia/loading.module';
import Prospect from '@/Models/Prospects';

import * as service from '@/services';

const $toast = useToast();

export const useCorporateProspectsStore = defineStore('corporateProspects', {
    persist: false,
    state: () => {
        return {
            allProspects: computed({
                get() { return sharedStore().allProspects },
                set(val) { sharedStore().allProspects = val }
            }),
            corporateProspects: [],
            corporateProspect: new Prospect(),
            prospectus: computed({
                get() { return sharedStore().prospectus },
                set(val) { sharedStore().prospectus = val }
            }),
            errors: null,
        }
    },
    getters: {
        getSelectedCorporateProspectus: state => state.corporateProspect,
        getProspects: state => state.corporateProspects,
        getProspectById: state => id => {
            return state.corporateProspects.find(p => p.prospectID === id);
        },
        getActiveProspects: state => state.corporateProspects,
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        async fetchCorporateProspectsByBranchOfficeId(branchOfficeId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/corporateProspects/branchOffice/${branchOfficeId}/active`);
                if(data && data.status) {
                    useLoadingStore().loading = false;
                    const corporateProspects = data.map((cp) => new Prospect(cp));
                    this.corporateProspects = corporateProspects;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchCorpProspectusById(prospectusId) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/corporateProspects/${prospectusId}`);
                if(data && data.status) {
                    useLoadingStore().loading = false;
                    this.corporateProspect = new Prospect(data.data);
                    this.prospectus = new Prospect(data.data);
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchCorporateProspectusById(prospectusId) {
            return new Promise((resolve, reject) => {
                service.prospectsApi.get(`/api/corporateProspects/${prospectusId}`)
                .then(({ data }) => {
                    if(data) {
                        resolve(data.data[0] || {});
                    }
                })
                .catch((error) => {
                    reject(error);
                })
            })
        },
        async createProspect(prospectData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.post(`/api/corporateProspects`, prospectData);
                if(data && data.status) {
                    $toast.success(getLocaleMessage('prospects', 'creatingSuccess'));
                    const prospectus = data.data;
                    this.allProspects.unshift(prospectus);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateProspect(prospectData) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.put(`/api/corporateProspects`, prospectData);
                if(data && data.status) {
                    $toast.success(getLocaleMessage('prospects', 'editingSuccess'));
                    const prospectus = data.data;
                    const index = this.allProspects.findIndex(c => c.prospectusId === prospectus.prospectusId);
                    if (index !== -1) {
                        this.allProspects.splice(index, 1, prospectus);
                        this.allProspects.sort((a, b) => (new Date(a.updatedAt) < new Date(b.updatedAt)) ? -1 : 1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
    }
});