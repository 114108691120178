import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import Prospectus from '@/Models/Prospects';
import LoanApplication from '@/Models/LoanApplications/LoanApplication';

import * as service from '@/services';

const $toast = useToast();

export const sharedStore = defineStore({
    id: 'shared',
    state: () => ({
        allProspects: [],
        prospectus: new Prospectus({}),
        mixedLoanApplications: [],
        loanApplications: [], // A list of Loan applications by the same PersonType (not a mix)
        loanApplication: new LoanApplication({}),
        unratedLoanApplications: [],
        errors: null,
    }),
    getters: {
        getAllProspects: state => state.allProspects,
        getProspectus: state => state.prospectus,
        getProspectusFullname: (state) => {
            if (state.prospectus.prospectusId === 0 || state.prospectus.generalInformation === null) return;

            const { generalInformation } = state.prospectus;
            if (state.prospectus.personTypeId === 1) {
                return `${generalInformation.firstName} ${generalInformation.mothersMaidenName} ${generalInformation.lastName}`;
            } else {
                return `${generalInformation.businessName}`;
            }
        },
        getProspectusById: state => (id = 0) => (id ? state.allProspects.find(p => p.prospectusId === id) : new Prospectus()) ?? new Prospectus(),
        getLoanApplications: state => state.loanApplications,
        getLoanApplication: state => state.loanApplication,
        getLoanApplicationById: state => (id = 0) => (id ? state.loanApplications.find(l => l.loanApplicationId === id) : {}) ?? {},
        // This getter is shared between Individual and Corporate Loan Applications stores:
        getUnratedLoanApplications: state => state.unratedLoanApplications,
    },
    actions: {
        setErrors(errors) {
            this.errors = errors;
        },
        setSelectedProspectus(id) {
            this.prospectus = this.getProspectusById(id);
        },
        resetProspectsList() {
            this.allProspects = [];
        },
        setSelectedLoanApplication(id) {
            this.loanApplication = this.getLoanApplicationById(id);
        },
        resetLoanApplicationsList() {
            this.loanApplications = [];
        },
        resetUnratedLoanApplicationsList() {
            this.unratedLoanApplications = [];
        },
        async fetchProspectus(prospectus) {
            try {
                useLoadingStore().loading = true;
                const personTypeId = prospectus.personTypeId;
                const id = prospectus.prospectusId;
                let data = null;

                if (personTypeId === 1) {
                    ({ data } = await service.prospectsApi.get(`/api/individualProspects/${id}`));
                } else {
                    ({ data } = await service.prospectsApi.get(`/api/corporateProspects/${id}`));
                }
                if(data) {
                    this.prospectus = new Prospectus(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error("Ocurrió un error al cargar el prospecto.");
            }
        },
    }
});
