<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Identificaciones</h3>
    </div>
    <br>

    <div class="position-relative col-sm-12">

        <IdentificationTable :list="getIdentifications" :currentPage="currentPage" :itemsPerPage="itemsPerPage" @click-on-edit-btn="openEditionForm">
        </IdentificationTable>
        <Pagination :list="getIdentifications" :currentPage="currentPage" :itemsPerPage="itemsPerPage">
        </Pagination>

        <Modal>
            <EditPFDetails v-if="modalContent == 1" :objectsIds="objectsIds" :formTitle="`Actualizar Identificación de Persona Física`" :notBeneficiary="true" @closeModal="modalStatus"></EditPFDetails>
            <EditPMDetails v-if="modalContent == 2" :objectsIds="objectsIds" :notBeneficiary="true" @closeModal="modalStatus"></EditPMDetails>
        </Modal>

    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import IdentificationTable from '@/Components/Tables/IdentificationTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import { identificationsStore } from '@/pinia/identifications.module';

import Modal from '@/Components/Bootstrap/Modal.vue';
import EditPFDetails from '@/Components/Forms/LoanApplications/PLD/IdentificationPFDetails.vue';
import EditPMDetails from '@/Components/Forms/LoanApplications/PLD/IdentificationPMDetails.vue';

export default {
    components: {
        IdentificationTable,
        Pagination,
        Modal,
        EditPFDetails,
        EditPMDetails,
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 10,
            modalId: 'modal-lg',
            modalContent: 0,
            selectedRecordId: 0,
            personTypeId: 0,
            objectsIds: { prospectusId: 0 },
        }
    },
    computed: {
        ...mapState(identificationsStore, ['getIdentifications'])
    },
    methods: {
        ...mapActions(identificationsStore, ['fetchIdentificationsById']),
        openEditionForm(selectedItem) {
            this.objectsIds.prospectusId = selectedItem.prospectusId;
            this.modalContent = selectedItem.personTypeId;
        },
        modalStatus(status) {
            if (status) {
                $(`#${this.modalId}`).modal('hide');
            }
        },
    },
    watch: {
    },
    created() {
    },
    mounted() {
        this.fetchIdentificationsById();
        $(`#${this.modalId}`).on('hidden.bs.modal', () => {
            this.modalContent = 0;
        });
    }
}
</script>