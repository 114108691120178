import axios from 'axios';

/**
 * Función para obtener la URL de la API según el servicio
 * @date 3/18/2024 - 8:07:35 PM
 *
 * @param {*} [service=null]
 * @param {*} [port=null] - Puerto según la configuración en aspnet core (launchSettings.json)
 * @returns {string}
 */
function getApiUrl(service = null, port = null) {
  // Returns true if the current environment is production
  const isProdEnv = import.meta.env.PROD;
  if (!port) {
    return `https://api-dev.adnsolutionscred.com/${service}`;
  }
  return `${isProdEnv ? `https://api-dev.adnsolutionscred.com/${service}` : `http://localhost:${port}`}`;
}


/**
 * Función para crear una instancia de Axios para un servicio específico
 * @date 3/18/2024 - 11:35:32 PM
 *
 * @param {*} [service=null]
 * @param {*} [port=null]
 * @returns {import('axios').AxiosStatic }
 */
function createAxiosInstance(service = null, port = null) {
  const baseURL = getApiUrl(service, port);

  return axios.create({
    baseURL: baseURL,
    // Otros ajustes de configuración de Axios
  });
}

// Exporta las instancias de Axios para cada servicio
export const profilesApi = createAxiosInstance('profile', 5216);
export const statusApi = createAxiosInstance('status', 5238);

export const companiesApi = createAxiosInstance('companies', 7138);
export const branchOfficeApi = createAxiosInstance('branchoffices', 5107);
export const productsApi = createAxiosInstance('products', 5145);
export const documentationApi = createAxiosInstance('documentation', 5113);
export const holidaysApi = createAxiosInstance('holidays', 5292);
export const taxesApi = createAxiosInstance('taxes', 5157);
export const financialFundersApi = createAxiosInstance('financialFunders', 5260);
export const banksApi = createAxiosInstance('banks', 5003);
export const currenciesApi = createAxiosInstance('currencies', 5190);

export const prospectsApi = createAxiosInstance('prospects', 5020);
export const quotationsApi = createAxiosInstance('quotations', 5235);
export const loanApplicationsApi = createAxiosInstance('loanApplications', 5091);
export const certificatesApi = createAxiosInstance('certificates', 5009);
export const questionnairesApi = createAxiosInstance('questionnaires', 5037);
export const creditScoresApi = createAxiosInstance('creditScores', 5108);
