<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th>Folio de Documentos</th>
                <th>Matriz y/o Sucursales</th>
                <th>Tipo de Expediente</th>
                <th>Documentación</th>
                <th>Folio de Prospectos</th>
                <th>Nombre de Prospectos</th>
                <th>Estatus</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="getDataPage().length">
                <tr v-for="item in getDataPage()" :key="item.id">
                    <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                    <td>{{ item.branchOffice.name }}</td>
                    <td>{{ item.financialRecord.recordType.name }}</td>
                    <td>{{ item.financialRecord.name }}</td>
                    <td>{{ item.prospectus.referenceNumber }}</td>
                    <td>{{ prospectusFullName(item.prospectus) }}</td>
                    <td>{{ item.status.name }}</td>
                    <td>
                        <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-lg"
                            @click.prevent="editItem(item)"><i class="fa fa-edit"></i>
                        </PrimaryButton>
                        <!-- <PrimaryButton class="btn-danger" @click.prevent="deleteItem(item)"><i class="fa fa-trash"></i>
                        </PrimaryButton> -->
                    </td>
                </tr>
            </template>
            <tr v-else>
                <td colspan="7" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
        }
    },
    emits: ['edit-item', 'delete-item'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getCompanies() {
            return this.list;
        }
    },
    methods: {
        getDataPage() {
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            let end = (this.currentPage * this.itemsPerPage);
            return this.list.slice(start, end);
        },
        editItem(item) {
            this.$emit('edit-item', item);
        },
        deleteItem(item) {
            this.$emit('delete-item', item);
        },
        prospectusFullName(prospectus) {
            const generalInfo = prospectus.generalInformation;
            if (prospectus.personTypeId == 1) {
                return `${generalInfo.firstName} ${generalInfo.lastName} ${generalInfo.mothersMaidenName}`;
            } else {
                return `${generalInfo.businessName}`;
            }
        }
    },
    created() {
        this.getDataPage();
    }
}
</script>