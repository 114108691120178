<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Solicitudes</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Solicitudes</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Solicitudes</h3>
                                <div class="d-flex">
                                    <PrimaryButton class="btn btn-primary mr-3 " data-toggle="modal" :data-target="modalDataTarget" @click="modalContent = 1; editing = false">
                                        Buscar Prospecto
                                    </PrimaryButton>
                                    <PrimaryButton class="btn btn-primary mr-3 " data-toggle="modal" :data-target="modalDataTarget" @click="modalContent = 3; editing = false">
                                        Carátula Anexo A
                                    </PrimaryButton>
                                    <PrimaryButton class="btn btn-primary mr-3 " data-toggle="modal" :data-target="modalDataTarget" @click="modalContent = 4; editing = false">
                                        Pagaré
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex"></div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <LoanApplicationTable 
                                :list="this.getMixedLoanApplications" 
                                :dataTarget="modalDataTarget" 
                                :currentPage="currentPage" 
                                :itemsPerPage="itemsPerPage" 
                                @edit-loan-application="openEditionForm">
                                </LoanApplicationTable>
                                <Pagination 
                                :list="this.getMixedLoanApplications" 
                                :currentPage="currentPage" 
                                :itemsPerPage="itemsPerPage"> 
                                </Pagination>

                                <Modal :modalId="modalId" :customClass="modalId">
                                    <div v-if="modalContent == 1">
                                        <ProspectsMinTable 
                                            :list="getAllProspects"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage"
                                            :callingFromLoanApplication="true"
                                            @change-modal-content="setModalContent"
                                            @selectedProspectus="setSelectedProspectus">
                                        </ProspectsMinTable>
                                        <Pagination 
                                        :list="getAllProspects" 
                                        :itemsPerPage="itemsPerPage" 
                                        :currentPage="currentPage" 
                                        @current-page="currentPage = $event">
                                        </Pagination>
                                    </div>

                                    <div v-if="modalContent == 2">
                                        <QuotationsMinTable
                                            :list="getQuotations"
                                            :itemsPerPage="itemsPerPage"
                                            :currentPage="currentPage"
                                            @change-modal-content="setModalContent"
                                            @selectedQuotation="setSelectedQuotation"
                                        ></QuotationsMinTable>
                                        <Pagination 
                                        :list="getQuotations" 
                                        :itemsPerPage="itemsPerPage" 
                                        :currentPage="currentPage" 
                                        @current-page="currentPage = $event">
                                        </Pagination>
                                    </div>

                                    <Caratula v-if="modalContent == 3 && editing == false" ></Caratula>
                                    <Pagare v-if="modalContent == 4 && editing == false" :numberOfMonths="numberOfMonths"></Pagare>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import $ from 'jquery';
import Prospectus from '@/Models/Prospects';
import Quotation from '@/Models/Quotation';

import { mapState, mapActions } from 'pinia';

import { useBranchOfficesStore } from '@/pinia/branchOffices.module';
import { useStatusStore } from '@/pinia/status.module';
import { useFinancialFundersStore } from '@/pinia/financialFunders.module';
import { useApplicantTypesStore } from '@/pinia/LoanApplications/applicantTypes.module.js';

import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useCorporateProspectsStore } from '@/pinia/Prospects/corporate.module';
import { useQuotationsStore } from '@/pinia/quotations.module';


import PrimaryButton from '@/Components/PrimaryButton.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import Caratula from '@/Components/Forms/LoanApplications/Legal/CaratulaAnexoA.vue';
import Pagare from '@/Components/Forms/LoanApplications/Legal/Pagare.vue';
import AddPM from '@/Components/Forms/LoanApplications/AddPM.vue';
import EditPF from '@/Components/Forms/LoanApplications/EditPF.vue';
import EditPM from '@/Components/Forms/LoanApplications/EditPM.vue';
import Checklist from '@/Components/Forms/LoanApplications/Checklist.vue';
import ProspectsMinTable from '@/Components/Tables/ProspectsMinTable.vue'; // A light version of the ProspectsTable
import QuotationsMinTable from '@/Components/Tables/QuotationsMinTable.vue'; // A light version of the QuotationsTable
import LoanApplicationTable from '@/Components/Tables/LoanApplicationsTable.vue';
export default {
    components: { 
        PrimaryButton, 
        Modal, 
        ProspectsMinTable, 
        QuotationsMinTable, 
        LoanApplicationTable, 
        Pagination, 
        Caratula, 
        Pagare, 
        AddPM, 
        EditPF, 
        EditPM, 
        Checklist, 
    },
    data() {
        return {
            modalId: 'modal-xl',
            modalDataTarget: '#modal-xl',
            editing: false,
            modalContent: 0, // (1: Prospects Table), (2: Quotations Table), (3: AddPF), (4: AddPM), (5: EditPF), (6: EditPM)
            selectedRecord: {},
            selectedRecordId: null,
            selectedPropectusId: null,
            selectedQuotationId: null,
            selectedProspectus: new Prospectus(),
            selectedQuotation: new Quotation({}),
            currentPage: 1,
            itemsPerPage: 10,
            numberOfMonths: 12,
        }
    },
    computed: {
        ...mapState(useBranchOfficesStore, ['getActiveBranchOffices']),
        ...mapState(useStatusStore, ['getAllStatusByType', 'getSelectedStatus']),
        ...mapState(useFinancialFundersStore, ['getFinancialFunders']),
        ...mapState(useApplicantTypesStore, ['getApplicantTypes']),
        ...mapState(useIndividualLoanApplicationStore, ['getMixedLoanApplications']),
        ...mapState(useIndividualProspectsStore, ['getAllProspects']),
        ...mapState(useQuotationsStore, ['getQuotations']),
    },
    methods: {
        ...mapActions(useBranchOfficesStore, ['fetchActiveBranchOffices']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        ...mapActions(useFinancialFundersStore, ['fetchActiveFinancialFundersByCompany']),
        ...mapActions(useApplicantTypesStore, ['fetchApplicantTypes']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchMixedLoanApplications']),
        ...mapActions(useIndividualProspectsStore, ['fetchMixedProspects', 'fetchIndividualProspectusById']),
        ...mapActions(useCorporateProspectsStore, ['fetchCorpProspectusById']),
        ...mapActions(useQuotationsStore, ['getQuotationsByProspectusId']),
        modalStatus(status) {
            if (status) {
                $('#modal-xl').modal('hide');
                this.modalContent = 0;
                this.editing = false;
            }
        },
        openEditionForm(selectedItem) {
            this.selectedRecordId = selectedItem.id;
            this.selectedPropectusId = selectedItem.prospectusId;
            this.selectedQuotationId = selectedItem.quotationId;
            // Dependiendo del tipo de persona, se mostrará un formulario u otro
            this.modalContent = selectedItem.personTypeId === 1 ? 5 : 6;
            this.editing = true;
        },
        setSelectedProspectus(prospectus) {
            this.selectedProspectus = prospectus;
            this.getQuotationsByProspectusId(prospectus.prospectusId);
        },
        setSelectedQuotation(quotation) {
            // TODO: Make a request to get the quotation with the 'product' and its 'quotationdetails'
            this.selectedQuotation = quotation;
            this.modalContent = this.selectedProspectus.personTypeId === 1 ? 3 : 4;
        },
        setModalContent(number) {
            this.modalContent = number;
        }
    },
    created() {
        // this.fetchActiveFinancialFundersByCompany();
        // this.fetchActiveBranchOffices();
        // this.fetchApplicantTypes();
        // this.fetchStatus();
        // this.fetchMixedLoanApplications();
        // this.fetchMixedProspects();
    },
    mounted() {
        $(this.modalDataTarget).on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
            this.selectedRecordId = 0;
        });
    }
}
</script>