<template>
    <Form ref="caratulaA" @submit="onSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ formTitle }} Carátula Anexo A
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <FieldGroup v-model="form.productName" name="productName" colClass="col-sm-12"
                    label="Nombre del Producto:" placeholder="Nombre del Producto" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="form.productType" name="name" colClass="col-sm-12" label="Tipo de Producto:"
                    placeholder="Tipo de Producto" rules="required|max:255">
                </FieldGroup>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Acreditante (GOLDBERGFUND) KVF GROUP, S.A. DE C.V.</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.accreditorAddress" name="name" colClass="col-sm-6" label="Domicilio:"
                        placeholder="Domicilio" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.legalRepresentative" name="name" colClass="col-sm-6"
                        label="Representante Legal:" placeholder="Representante Legal" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.legalRepresentative" name="name" colClass="col-sm-6"
                        label="Representante Legal:" placeholder="Representante Legal" rules="required|max:255">
                    </FieldGroup>
                    <div class="form-group col-sm-6">
                        <label for="name">Poder Notarial Número y Fecha:</label>
                        <Field v-model="form.accreditorAddress" type="number" class="form-control" name="name"
                            placeholder="Poder Notarial Número" rules="required|max:255" />
                        <Field v-model="form.accreditorAddress" type="date" class="form-control" name="name"
                            rules="required|max:255" />
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Acreditado</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.accreditorName" name="accreditorName" colClass="col-sm-6" label="Nombre:"
                        placeholder="Nombre" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.rfc" name="rfc" colClass="col-sm-6" label="RFC:" placeholder="RFC"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.curp" name="curp" colClass="col-sm-6" label="CURP:" placeholder="CURP"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.dateOfBirth" name="dateOfBirth" colClass="col-sm-6"
                        label="Fecha de Nacimiento:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.birthState" name="birthState" colClass="col-sm-6"
                        label="Lugar de Nacimiento:" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroActaConstitutiva" name="numeroActaConstitutiva" colClass="col-sm-6"
                        label="No. de Acta Constitutiva:" placeholder="No. de Acta Constitutiva"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaActaConstitutiva" name="fechaActaConstitutiva" colClass="col-sm-6"
                        label="Fecha de Acta Constitutiva:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroNotario" name="numeroNotario" colClass="col-sm-6"
                        label="Número del Notario:" placeholder="Número del Notario" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.nombreNotario" name="nombreNotario" colClass="col-sm-6"
                        label="Nombre del Notario:" placeholder="Nombre del Notario" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.folioRegistro" name="folioRegistro" colClass="col-sm-6"
                        label="Folio de Registro:" placeholder="Folio de Registro" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaRegistro" colClass="col-sm-6" name="fechaRegistro"
                        label="Fecha de Registro:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.email" colClass="col-sm-6" name="email" label="Correo Electrónico:"
                        placeholder="Correo Electrónico" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.phoneNumber" colClass="col-sm-6" name="phoneNumber" label="Teléfono Fijo:"
                        placeholder="Teléfono Fijo" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.cellPhoneNumber" colClass="col-sm-6" name="cellPhoneNumber"
                        label="Celular:" placeholder="Celular" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.contactName" colClass="col-sm-6" name="contactName"
                        label="Nombre de Contacto:" placeholder="Nombre de Contacto" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.address" colClass="col-sm-6" name="address" label="Domicilio:"
                        placeholder="Domicilio" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.legalRepresentativeName" colClass="col-sm-6"
                        name="legalRepresentativeName" label="Nombre del Representante Legal:"
                        placeholder="Nombre del Representante Legal" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.rfc" colClass="col-sm-6" name="rfc" label="RFC:" placeholder="RFC"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.curp" name="curp" colClass="col-sm-6" label="CURP:" placeholder="CURP"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.phoneNumber" colClass="col-sm-6" name="phoneNumber" label="Teléfono:"
                        placeholder="Teléfono" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.email" colClass="col-sm-6" name="email" label="Correo Electrónico:"
                        placeholder="Correo Electrónico" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroPoder" colClass="col-sm-6" name="numeroPoder"
                        label="Nùmero del Poder:" placeholder="Nùmero del Poder" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaPoder" colClass="col-sm-6" name="fechaPoder" label="Fecha del Poder:"
                        type="date" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.folioRegistro" name="folioRegistro" colClass="col-sm-6"
                        label="Folio de Registro:" placeholder="Folio de Registro" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaRegistro" colClass="col-sm-6" name="fechaRegistro"
                        label="Fecha de Registro:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroNotario" name="numeroNotario" colClass="col-sm-6"
                        label="Número del Notario:" placeholder="Número del Notario" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.nombreNotario" name="nombreNotario" colClass="col-sm-6"
                        label="Nombre del Notario:" placeholder="Nombre del Notario" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.phoneNumber" colClass="col-sm-6" name="phoneNumber" label="Teléfono:"
                        placeholder="Teléfono" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.folioRegistro" name="folioRegistro" colClass="col-sm-6"
                        label="Folio de Registro:" placeholder="Folio de Registro" rules="required|max:255">
                    </FieldGroup>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Obligado Solidario</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.rfc" name="rfc" colClass="col-sm-6" label="RFC:" placeholder="RFC"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.curp" name="curp" colClass="col-sm-6" label="CURP:" placeholder="CURP"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.dateOfBirth" name="dateOfBirth" colClass="col-sm-6"
                        label="Fecha de Nacimiento:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.birthState" name="birthState" colClass="col-sm-6"
                        label="Lugar de Nacimiento:" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroActaConstitutiva" name="numeroActaConstitutiva" colClass="col-sm-6"
                        label="No. de Acta Constitutiva:" placeholder="No. de Acta Constitutiva"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaActaConstitutiva" name="fechaActaConstitutiva" colClass="col-sm-6"
                        label="Fecha de Acta Constitutiva:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.legalRepresentativeName" colClass="col-sm-6"
                        name="legalRepresentativeName" label="Nombre del Representante Legal:"
                        placeholder="Nombre del Representante Legal" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.rfc" colClass="col-sm-6" name="rfc" label="RFC:" placeholder="RFC"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.curp" name="curp" colClass="col-sm-6" label="CURP:" placeholder="CURP"
                        rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.phoneNumber" colClass="col-sm-6" name="phoneNumber" label="Teléfono:"
                        placeholder="Teléfono" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.email" colClass="col-sm-6" name="email" label="Correo Electrónico:"
                        placeholder="Correo Electrónico" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroPoder" colClass="col-sm-6" name="numeroPoder"
                        label="Nùmero del Poder:" placeholder="Nùmero del Poder" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaPoder" colClass="col-sm-6" name="fechaPoder" label="Fecha del Poder:"
                        type="date" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.folioRegistro" name="folioRegistro" colClass="col-sm-6"
                        label="Folio de Registro:" placeholder="Folio de Registro" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaRegistro" colClass="col-sm-6" name="fechaRegistro"
                        label="Fecha de Registro:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroNotario" name="numeroNotario" colClass="col-sm-6"
                        label="Número del Notario:" placeholder="Número del Notario" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.nombreNotario" name="nombreNotario" colClass="col-sm-6"
                        label="Nombre del Notario:" placeholder="Nombre del Notario" rules="required|max:255">
                    </FieldGroup>
                    <FieldGroup v-model="form.notaryAddress" colClass="col-sm-6" name="notaryAddress" label="Domicilio:"
                        placeholder="Domicilio" rules="required|max:255">
                    </FieldGroup>
                </div>
            </div>
            <!-- Generado por ChatPGT: -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Tasa de Interés Anual</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.capital" name="capital" colClass="col-sm-3" label="Capital:"
                        placeholder="Capital" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.seguroVida" name="seguroVida" colClass="col-sm-3"
                        label="Seguro Vida/Desempleo:" placeholder="Seguro Vida/Desempleo" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.seguroAuto" name="seguroAuto" colClass="col-sm-3" label="Seguro de Auto:"
                        placeholder="Seguro de Auto" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.interesOrdinario" name="interesOrdinario" colClass="col-sm-3"
                        label="Interés Ordinario:" placeholder="Interés Ordinario" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Interés Moratorio</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.moratorioCapital" name="moratorioCapital" colClass="col-sm-3"
                        label="Capital:" placeholder="Capital" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.moratorioSeguroVida" name="moratorioSeguroVida" colClass="col-sm-3"
                        label="Seguro Vida/Desempleo:" placeholder="Seguro Vida/Desempleo" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.moratorioSeguroAuto" name="moratorioSeguroAuto" colClass="col-sm-3"
                        label="Seguro de Auto:" placeholder="Seguro de Auto" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.interesMoratorio" name="interesMoratorio" colClass="col-sm-3"
                        label="Interés Moratorio:" placeholder="Interés Moratorio" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">CAT (Costo Anual Total)</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.cat" name="cat" colClass="col-sm-3" label="%:" placeholder="%"
                        rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaCalculoCat" name="fechaCalculoCat" colClass="col-sm-3"
                        label="Fecha de Cálculo:" type="date" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Detalles del Contrato</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.montoContrato" name="montoContrato" colClass="col-sm-3"
                        label="Monto del Contrato:" placeholder="Monto del Contrato" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.plazoContrato" name="plazoContrato" colClass="col-sm-3"
                        label="Plazo del Contrato:" placeholder="Plazo del Contrato" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaPago" name="fechaPago" colClass="col-sm-3" label="Fecha de Pago:"
                        type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaCorte" name="fechaCorte" colClass="col-sm-3" label="Fecha de Corte:"
                        type="date" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Comisiones / Penalizaciones</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.apertura" name="apertura" colClass="col-sm-6" label="Apertura:"
                        placeholder="Apertura" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.pagoTardio" name="pagoTardio" colClass="col-sm-6" label="Pago Tardío:"
                        placeholder="Pago Tardío" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.inactividad" name="inactividad" colClass="col-sm-4" label="Inactividad:"
                        placeholder="Inactividad" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.anualidad" name="anualidad" colClass="col-sm-4" label="Anualidad:"
                        placeholder="Anualidad" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.manejoCuenta" name="manejoCuenta" colClass="col-sm-4"
                        label="Manejo de Cuenta:" placeholder="Manejo de Cuenta" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Entrega del Recurso</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.institucionFinanciera" name="institucionFinanciera" colClass="col-sm-6"
                        label="Institución Financiera:" placeholder="Institución Financiera" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.nombreCuentahabiente" name="nombreCuentahabiente" colClass="col-sm-6"
                        label="Nombre del Cuentahabiente:" placeholder="Nombre del Cuentahabiente" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.numeroCuenta" name="numeroCuenta" colClass="col-sm-6"
                        label="Número de Cuenta:" placeholder="Número de Cuenta" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.cuentaClabe" name="cuentaClabe" colClass="col-sm-6" label="Cuenta Clabe:"
                        placeholder="Cuenta Clabe" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Información de Plástico</h3>
                </div>
                <div class="row card-body">
                    <FieldGroup v-model="form.numeroPlastico" name="numeroPlastico" colClass="col-sm-6" label="Número:"
                        placeholder="Número" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.fechaVencimiento" name="fechaVencimiento" colClass="col-sm-6"
                        label="Fecha de Vencimiento:" type="date" rules="required">
                    </FieldGroup>
                    <FieldGroup v-model="form.institucionEmisora" name="institucionEmisora" colClass="col-sm-6"
                        label="Institución Emisora y Comercializadora de la Tarjeta:"
                        placeholder="Institución Emisora y Comercializadora de la Tarjeta" rules="required">
                    </FieldGroup>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Seguros</h3>
                </div>
                <div class="row card-body">
                    <div class="card card-secondary">
                        <div class="card-header">
                            <h4 class="card-title">Desempleo</h4>
                        </div>
                        <div class="row card-body">
                            <!-- Desempleo Section -->
                            <FieldGroup v-model="form.desempleoAseguradora" name="desempleoAseguradora"
                                colClass="col-sm-3" label="Aseguradora:" placeholder="Aseguradora" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoCobertura" name="desempleoCobertura" colClass="col-sm-3"
                                label="Cobertura:" placeholder="Cobertura" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPoliza" name="desempleoPoliza" colClass="col-sm-3"
                                label="No. Póliza:" placeholder="No. Póliza" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoVigencia" name="desempleoVigencia" colClass="col-sm-3"
                                label="Vigencia:" placeholder="Vigencia" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaTotal" name="desempleoPrimaTotal"
                                colClass="col-sm-3" label="Prima Total:" placeholder="Prima Total" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaAnual" name="desempleoPrimaAnual"
                                colClass="col-sm-3" label="Prima Anual:" placeholder="Prima Anual" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTipoPago" name="desempleoTipoPago" colClass="col-sm-3"
                                label="Tipo de Pago:" placeholder="Tipo de Pago" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTasaAnual" name="desempleoTasaAnual" colClass="col-sm-3"
                                label="Tasa de Interés Anual:" placeholder="Tasa de Interés Anual" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoBeneficiario" name="desempleoBeneficiario"
                                colClass="col-sm-3" label="Beneficiario:" placeholder="Beneficiario" rules="required">
                            </FieldGroup>
                        </div>
                    </div>
                    <div class="card card-secondary">
                        <div class="card-header">
                            <h4 class="card-title">Vida</h4>
                        </div>
                        <div class="row card-body">
                            <!-- Vida Section -->
                            <FieldGroup v-model="form.desempleoAseguradora" name="desempleoAseguradora"
                                colClass="col-sm-3" label="Aseguradora:" placeholder="Aseguradora"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoCobertura" name="desempleoCobertura" colClass="col-sm-3"
                                label="Cobertura:" placeholder="Cobertura" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPoliza" name="desempleoPoliza" colClass="col-sm-3"
                                label="No. Póliza:" placeholder="No. Póliza" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoVigencia" name="desempleoVigencia" colClass="col-sm-3"
                                label="Vigencia:" placeholder="Vigencia" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaTotal" name="desempleoPrimaTotal"
                                colClass="col-sm-3" label="Prima Total:" placeholder="Prima Total"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaAnual" name="desempleoPrimaAnual"
                                colClass="col-sm-3" label="Prima Anual:" placeholder="Prima Anual"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTipoPago" name="desempleoTipoPago" colClass="col-sm-3"
                                label="Tipo de Pago:" placeholder="Tipo de Pago" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTasaAnual" name="desempleoTasaAnual" colClass="col-sm-3"
                                label="Tasa de Interés Anual:" placeholder="Tasa de Interés Anual"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoBeneficiario" name="desempleoBeneficiario"
                                colClass="col-sm-3" label="Beneficiario:" placeholder="Beneficiario"
                                rules="required">
                            </FieldGroup>
                        </div>
                    </div>
                    <div class="card card-secondary">
                        <div class="card-header">
                            <h4 class="card-title">Auto</h4>
                        </div>
                        <div class="row card-body">
                            <!-- Auto Section -->
                            <FieldGroup v-model="form.desempleoAseguradora" name="desempleoAseguradora" colClass="col-sm-3"
                                label="Aseguradora:" placeholder="Aseguradora" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoCobertura" name="desempleoCobertura" colClass="col-sm-3"
                                label="Cobertura:" placeholder="Cobertura" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPoliza" name="desempleoPoliza" colClass="col-sm-3"
                                label="No. Póliza:" placeholder="No. Póliza" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoVigencia" name="desempleoVigencia" colClass="col-sm-3"
                                label="Vigencia:" placeholder="Vigencia" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaTotal" name="desempleoPrimaTotal" colClass="col-sm-3"
                                label="Prima Total:" placeholder="Prima Total" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaAnual" name="desempleoPrimaAnual" colClass="col-sm-3"
                                label="Prima Anual:" placeholder="Prima Anual" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTipoPago" name="desempleoTipoPago" colClass="col-sm-3"
                                label="Tipo de Pago:" placeholder="Tipo de Pago" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTasaAnual" name="desempleoTasaAnual" colClass="col-sm-3"
                                label="Tasa de Interés Anual:" placeholder="Tasa de Interés Anual"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoBeneficiario" name="desempleoBeneficiario" colClass="col-sm-3"
                                label="Beneficiario:" placeholder="Beneficiario" rules="required">
                            </FieldGroup>
                        </div>
                    </div>
                    <div class="card card-secondary">
                        <div class="card-header">
                            <h4 class="card-title">Inmueble</h4>
                        </div>
                        <div class="row card-body">
                            <!-- Inmueble Section -->
                            <FieldGroup v-model="form.desempleoAseguradora" name="desempleoAseguradora" colClass="col-sm-3"
                                label="Aseguradora:" placeholder="Aseguradora" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoCobertura" name="desempleoCobertura" colClass="col-sm-3"
                                label="Cobertura:" placeholder="Cobertura" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPoliza" name="desempleoPoliza" colClass="col-sm-3"
                                label="No. Póliza:" placeholder="No. Póliza" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoVigencia" name="desempleoVigencia" colClass="col-sm-3"
                                label="Vigencia:" placeholder="Vigencia" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaTotal" name="desempleoPrimaTotal" colClass="col-sm-3"
                                label="Prima Total:" placeholder="Prima Total" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoPrimaAnual" name="desempleoPrimaAnual" colClass="col-sm-3"
                                label="Prima Anual:" placeholder="Prima Anual" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTipoPago" name="desempleoTipoPago" colClass="col-sm-3"
                                label="Tipo de Pago:" placeholder="Tipo de Pago" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoTasaAnual" name="desempleoTasaAnual" colClass="col-sm-3"
                                label="Tasa de Interés Anual:" placeholder="Tasa de Interés Anual"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.desempleoBeneficiario" name="desempleoBeneficiario" colClass="col-sm-3"
                                label="Beneficiario:" placeholder="Beneficiario" rules="required">
                            </FieldGroup>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-primary mt-3">
                <div class="card-header">
                    <h3 class="card-title">Garantía(s)</h3>
                </div>
                <div class="row card-body">
                    <div class="card card-secondary">
                        <div class="card-header">
                            <h4 class="card-title">Auto</h4>
                        </div>
                        <div class="row card-body">
                            <!-- Desempleo Section -->
                            <FieldGroup v-model="form.marcaAuto" name="marcaAuto"
                                colClass="col-sm-3" label="Marca:" placeholder="Marca" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.subMarcaAuto" name="subMarcaAuto" colClass="col-sm-3"
                                label="Submarca:" placeholder="Submarca" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.modeloAuto" name="modeloAuto" colClass="col-sm-3"
                                label="Modelo:" placeholder="Modelo" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.numSerieAuto" name="numSerieAuto" colClass="col-sm-3"
                                label="No. De Serie:" placeholder="No. De Serie" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.numMotorAuto" name="numMotorAuto"
                                colClass="col-sm-3" label="No. De Motor:" placeholder="No. De Motor" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.placasAuto" name="placasAuto"
                                colClass="col-sm-3" label="Placas:" placeholder="Placas" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.destinoAuto" name="destinoAuto" colClass="col-sm-3"
                                label="Destino:" placeholder="Destino" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.modeloAuto2" name="modeloAuto2" colClass="col-sm-3"
                                label="Modelo:" placeholder="Modelo" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.numMotorAuto2" name="numMotorAuto2"
                                colClass="col-sm-3" label="No. De Motor:" placeholder="No. De Motor" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.numFacturaAuto" name="numFacturaAuto"
                                colClass="col-sm-3" label="Número de factura:" placeholder="Número de factura" rules="required">
                            </FieldGroup>
                        </div>
                    </div>
                    <div class="card card-secondary">
                        <div class="card-header">
                            <h4 class="card-title">Bien Inmueble</h4>
                        </div>
                        <div class="row card-body">
                            <!-- Vida Section -->
                            <FieldGroup v-model="form.domicilioInmueble" name="domicilioInmueble"
                                colClass="col-sm-3" label="Domicilio:" placeholder="Domicilio"
                                rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.escrituraInmueble" name="escrituraInmueble" colClass="col-sm-3"
                                label="Escritura:" placeholder="Escritura" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.caracterisiticasInmueble" name="caracterisiticasInmueble" colClass="col-sm-3"
                                label="Características:" placeholder="Características" rules="required">
                            </FieldGroup>
                            <FieldGroup v-model="form.hipotecaInmueble" name="hipotecaInmueble" colClass="col-sm-3"
                                label="Hipoteca:" placeholder="Hipoteca" rules="required">
                            </FieldGroup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ createButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>
  
  <script>
  import { Form, Field } from 'vee-validate';
  import FieldGroup from '@/Components/Fields/FieldGroup.vue';

  export default {
    components: {
      FieldGroup
    },
    data() {
      return {
        form: {
          productName: '',
          productType: '',
          accreditorAddress: '',
          legalRepresentative: '',
          notarialPower: '',
          accreditorRFC: '',
          accreditedCURP: '',
          birthAndConstitution: '',
          notary: '',
          registration: '',
          email: '',
          mobile: '',
          fixedPhone: '',
          contactName: '',
          accreditedAddress: '',
          accreditedRepLegal: '',
          accreditedRFC: '',
          accreditedCURP2: '',
          powerNumber: '',
          powerDate: '',
          notary2: '',
          phone2: '',
          registration2: '',
          email2: '',
          registrationDate2: '',
          solidaryCURP: '',
          solidaryRepLegal: '',
          solidaryRFC: '',
          solidaryCURP2: '',
          solidaryBirth: '',
          solidaryPowerNumber: '',
          solidaryPowerDate: '',
          solidaryNotary: '',
          solidaryAddress: '',
          solidaryPhone: '',
          solidaryEmail: '',
          solidaryRegistration: ''
        }
      }
    },
    computed: {
        formTitle() {
            return this.$getLocaleMessage('formTitles', 'create')
        },
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel')
        },
        createButtonText() {
            return this.$getLocaleMessage('buttons', 'create')
        }
    },
    methods: {
        onSubmit(values) {
        console.log(values);
        // Add form submission logic here
      }
    }
  }
  </script>
  
  <style>
  /* .document {
    width: 80%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
  } */
  </style>
  