<template>
    <Form>
        <div class="modal-header">
            <h4 class="modal-title">
                Pagaré {{ numberOfMonths }} meses
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="card card-primary col-sm-12">
                    <div class="card-header">
                        <h3 class="card-title">Porcentajes</h3>
                    </div>
                    <div class="row card-body">
                        <FieldGroup v-model="form.anualInteresOrdinario" type="number" name="anualInteresOrdinario" colClass="col-sm-4" label="Anual Interés Ordinario:"
                            rules="required">
                        </FieldGroup>
                        <FieldGroup v-model="form.anualInteresMoratorio" type="number" name="anualInteresMoratorio" colClass="col-sm-4" label="Anual Interés Moratorio:"
                            rules="required">
                        </FieldGroup>
                        <FieldGroup v-model="form.interesDiario" type="number" name="interesDiario" colClass="col-sm-4" label="Diario:"
                            rules="required">
                        </FieldGroup>
                    </div>
                </div>
                <FieldGroup v-model="form.numeroPagare" name="numeroPagare" colClass="col-sm-12" label="Número de Pagaré:"
                    placeholder="Número de Pagaré" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.nombreSuscriptor" name="nombreSuscriptor" colClass="col-sm-12 col-md-12"
                    label="Suscriptor:" placeholder="Suscriptor" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.numeroCantidad" type="number" name="numeroCantidad"
                    colClass="col-sm-12 col-md-4" label="Cantidad:" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.letraCantidad" name="letraCantidad" colClass="col-sm-12 col-md-4"
                    label="Cantidad en letra:" placeholder="Cantidad en letra" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.fechaComprometidaPago" type="date" name="fechaComprometidaPago"
                    colClass="col-sm-12 col-md-4" label="Fecha comprometida de pago:" placeholder="Fecha comprometida de pago"
                    rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.numeroInteresOrdinario" type="number" name="numeroInteresOrdinario"
                    colClass="col-sm-12 col-md-6" label="Interés Ordinario:" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.letraInteresOrdinario" name="letraInteresOrdinario"
                    colClass="col-sm-12 col-md-6" label="Interés Ordinario en letra:"
                    placeholder="Interés Ordinario en letra" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.numeroInteresMoratorio" type="number" name="numeroInteresMoratorio"
                    colClass="col-sm-12 col-md-6" label="Interés Moratorio:" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.letraInteresMoratorio" name="letraInteresMoratorio"
                    colClass="col-sm-12 col-md-6" label="Interés Moratorio en letra:"
                    placeholder="Interés Moratorio en letra" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.date" type="date" name="date" colClass="col-sm-12 col-md-6" label="Fecha:"
                    rules="required">
                </FieldGroup>
                <div class="card card-primary col-sm-12">
                    <div class="card-header">
                        <h3 class="card-title">El Suscriptor</h3>
                    </div>
                    <div class="row card-body">
                        <FieldGroup v-model="form.nombreSuscriptor" name="nombreSuscriptor" colClass="col-sm-4" label="Nombre:"
                            placeholder="Nombre" rules="required">
                        </FieldGroup>
                        <FieldGroup v-model="form.domicilioSuscriptor" as="textarea" name="domicilioSuscriptor" colClass="col-sm-4" label="Domicilio:"
                            placeholder="Domicilio" rules="required">
                        </FieldGroup>
                        <FieldGroup v-model="form.telefonoSuscriptor" name="telefonoSuscriptor" colClass="col-sm-4" label="Teléfono:"
                            placeholder="Teléfono" rules="required">
                        </FieldGroup>
                    </div>
                </div>
                <div class="card card-primary col-sm-12">
                    <div class="card-header">
                        <h3 class="card-title">El Aval</h3>
                    </div>
                    <div class="row card-body">
                        <FieldGroup v-model="form.nombreAval" name="nombreAval" colClass="col-sm-4" label="Nombre:"
                            placeholder="Nombre" rules="required">
                        </FieldGroup>
                        <FieldGroup v-model="form.domicilioAval" as="textarea" name="domicilioAval" colClass="col-sm-4" label="Domicilio:"
                            placeholder="Domicilio" rules="required">
                        </FieldGroup>
                        <FieldGroup v-model="form.telefonoAval" name="telefonoAval" colClass="col-sm-4" label="Teléfono:"
                            placeholder="Teléfono" rules="required">
                        </FieldGroup>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ createButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import FieldGroup from '@/Components/Fields/FieldGroup.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        FieldGroup,
        PrimaryButton,
    },
    props: {
        numberOfMonths: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        rules: {
            type: [String, Function],
            default: '',
        },
        colClass: {
            type: String,
            default: 'col-sm-12'
        },
    },
    data() {
        return {
            form: {},
            interesDiarioPorcentaje: 0,
        }
    },
    computed: {
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel')
        },
        createButtonText() {
            return this.$getLocaleMessage('buttons', 'create')
        },
    },
    methods: {
        onSubmit(values) {
            console.log(values);
            // Add form submission logic here
        },
    },
}
</script>