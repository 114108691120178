<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Beneficiarios</h3>
        <div class="d-flex">
            <PrimaryButton class="btn btn-primary mr-3" data-toggle="modal" :data-target="modalDataTarget"
                @click="modalContent = 1; editing = false">
                Buscar Identificación de Prospectos
            </PrimaryButton>
        </div>
    </div>

    <div class="position-relative col-sm-12">

        <LoanApplicationsTable :list="getMixedLoanApplications" :dataTarget="modalDataTarget" :currentPage="currentPage"
            :itemsPerPage="itemsPerPage" @edit-loan-application="openEditionForm">
        </LoanApplicationsTable>
        <Pagination :list="getMixedLoanApplications" :currentPage="currentPage" :itemsPerPage="itemsPerPage">
        </Pagination>

        <Modal :modalId="modalId" :customClass="modalId">

            <div v-if="modalContent === 1">
                <LoanApplicationsMinTable :list="getMixedLoanApplications" @selected-item="setSelectedLoanApplication">
                </LoanApplicationsMinTable>
                <Pagination :list="getMixedLoanApplications">
                </Pagination>
            </div>

            <IdentificationDetailsPF v-if="modalContent === 2 && editing === false && personTypeId === 1"
                :objectsIds="objectsIds" :formTitle="beneficiaryFormTitle" :notBeneficiary="false">
            </IdentificationDetailsPF>
            <IdentificationDetailsPM v-if="modalContent == 3 && editing == false && personTypeId === 2"
                :objectsIds="objectsIds" :formTitle="beneficiaryFormTitle" :notBeneficiary="false"></IdentificationDetailsPM>

        </Modal>

    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useCorporateProspectsStore } from '@/pinia/Prospects/corporate.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import LoanApplicationsTable from '@/Components/Tables/LoanApplicationsTable.vue';
import LoanApplicationsMinTable from '@/Components/Tables/LoanApplicationsMinTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import IdentificationDetailsPF from '@/Components/Forms/LoanApplications/PLD/IdentificationPFDetails.vue';
import IdentificationDetailsPM from '@/Components/Forms/LoanApplications/PLD/IdentificationPMDetails.vue';

export default {
    components: {
        PrimaryButton,
        LoanApplicationsTable,
        LoanApplicationsMinTable,
        Pagination,
        Modal,
        IdentificationDetailsPF,
        IdentificationDetailsPM,
    },
    computed: {
        beneficiaryFormTitle() {
            const action = this.$getLocaleMessage('formTitles', 'create');
            if (this.personTypeId === 1) {
                return action + ' ' + "DATOS Y DOCUMENTOS DE IDENTIFICACIÓN DEL BENEFICIARIO CONTROLADOR QUE SEAN PERSONAS FÍSICAS EXTRANJERAS, CON LAS CONDICIONES DE ESTANCIA DE VISITANTE O DISTINTA A LAS ESTABLECIDAS EN LA FRACCIÓN I DEL ARTÍCULO 12 DE LAS REGLAS, EN TÉRMINOS DE LA LEY DE MIGRACIÓN.";
            } else {
                return action + ' ' + "DATOS Y DOCUMENTOS DE IDENTIFICACIÓN DEL BENEFICIARIO CONTROLADOR QUE SEAN PERSONAS MORALES DE NACIONALIDAD MEXICANA.";
            }
        },
    }
}
</script>